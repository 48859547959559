$header-height: 60px;
$header-min-width: 900px;

$navigation-width: 280px;
$navigation-item-height: 48px;

$content-padding: 20px;
$content-min-width: 900px;
$content-max-width: 1280px;

$page-padding: 40px;

//content

//basic comonent asset
$basic-border-radius: 10px;
