.card-signout {
  width: 100%;
  display: flex;
  flex-direction: column;
  .content__box {
    display: flex;
    & > ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      li {
        display: flex;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
        & > header {
          min-width: 200px;
          min-height: 40px;
          display: flex;
          align-items: center;
          color: $header-text-color;
          font-weight: bold;
          box-sizing: border-box;
        }
        .value {
          flex: 1;
          display: flex;
          align-items: center;
          width: 100%;
          border: solid 1px #bdbdbd;
          border-radius: $form-border-radius;
          padding: 8px 16px;
          min-height: 56px;
          box-sizing: border-box;
          background: transparent;
          color: #9d9e9d;
          p {
            white-space: pre-line;
          }
        }
      }
    }
  }

  .guide__box {
    margin-top: $content-padding;
    display: flex;
    flex-direction: column;
    color: $placeholder-text-color;
  }
}

.card-memo {
  display: flex;
  flex-direction: column;

  .input__box {
    padding-bottom: 20px;
    border-bottom: solid 1px $table-border-color;
    margin-bottom: 20px;
  }

  .list__box {
    display: flex;
    ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      li {
        display: flex;
        flex-direction: column;
        border: solid 1px #bdbdbd;
        border-radius: $form-border-radius;
        padding: 16px;
        box-sizing: border-box;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 16px;
        }
        & > header {
          display: flex;
          justify-content: space-between;
          .id__box {
            span {
              color: $header-text-color;
              font-weight: bold;
            }
            .date {
              font-weight: normal;
              margin-left: 8px;
            }
          }
        }

        .text__box {
          display: flex;
          p {
            line-height: 1.5;
            white-space: pre-line;
            color: $defualt-text-color;
          }
        }
      }
    }
  }
}

.card-reply {
  display: flex;
  flex-direction: column;

  & > header {
    display: flex;
    margin-bottom: $content-padding;
    justify-content: space-between;
    align-items: center;

    .text__box {
      display: flex;
      align-items: center;
      h3 {
        margin-right: 5px;
      }

      span {
        &.Inquiry {
          font-size: 13px;
          color: $secondary-color;
        }
        &.Complete {
          font-size: 13px;
          color: $primary-color;
        }
        &.label {
          font-size: 13px;
          margin-left: 10px;
          color: $placeholder-text-color;
        }
      }
    }
  }
  .input__box {
    display: flex;
    flex-direction: column;
    & > article {
      margin-bottom: $content-padding;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.card-project-status {
  display: flex;
  flex-direction: column;

  .content__box {
    margin-top: $content-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .status__box {
      display: flex;
      & > header {
        min-width: 100px;
        margin-right: $content-padding;
        span {
          font-weight: bold;
          color: $header-text-color;
        }
      }
      .value {
        display: flex;
        align-items: center;
        span {
          line-height: 24px;
          color: $defualt-text-color;
          &.degree {
            margin-right: 5px;
          }

          &.date {
            margin-left: 5px;
            font-size: 14px;
            color: $placeholder-text-color;
          }
        }
      }
    }
    .action__box {
      display: flex;
      ul {
        display: flex;
        align-items: center;
        li {
          margin-right: $content-padding;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
