.form-text-input {
  display: flex;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
}

.form-date-input {
  @extend .form-text-input;
  div.input__box {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    width: 100%;
    position: relative;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
    input {
      font: inherit;
      letter-spacing: inherit;
      color: currentColor;
      padding: 4px 0 5px;
      border: 0;
      box-sizing: content-box;
      background: none;
      height: 1.4375em;
      margin: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      min-width: 0;
      width: 100%;
      -webkit-animation-name: mui-auto-fill-cancel;
      animation-name: mui-auto-fill-cancel;
      -webkit-animation-duration: 10ms;
      animation-duration: 10ms;
      padding: 16.5px 14px;
    }
  }
}

.form-select-box {
  display: flex;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
}

.form-check-box {
  display: flex;
  min-height: 56px;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
  .grid__box {
    border: solid 1px $form-border-color;
    border-radius: $form-border-radius;
    padding: 10px 16px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    & > label {
      width: fit-content;
    }
  }
}

.form-radio-box {
  display: flex;
  min-height: 56px;

  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormGroup-root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    & > label {
      min-height: 56px;
      width: fit-content;
      margin-right: $content-padding;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.form-date-picker-box {
  display: flex;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
}

.form-date-time-picker-box {
  display: flex;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
}

.form-file-uploader {
  display: flex;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }

  .preview__box {
    display: flex;
    flex-direction: column;
    position: relative;

    .img__box {
      display: flex;
      width: 100%;
      max-width: 260px;
      border: solid 1px $form-border-color;
      border-radius: $form-border-radius;
      overflow: hidden;
      padding: $content-padding;
      position: relative;
      box-sizing: border-box;
      img {
        cursor: pointer;
        width: 100%;
      }
    }

    .file__box {
      display: flex;
      align-items: center;
      width: 100%;
      border: solid 1px $form-border-color;
      border-radius: $form-border-radius;
      padding: 0px 16px;
      height: 56px;
      box-sizing: border-box;
      span {
        word-break: break-all;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .close__box {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      right: 0px;
      top: 0px;
      z-index: 10;
      svg {
        cursor: pointer;
      }
    }
  }

  .input__box {
    display: none;
  }
}

.form-file-multiple-uploader {
  display: flex;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }

  .grid__box {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    &.image {
      li {
        min-height: 180px;
      }
    }
    &.file {
      li {
        min-height: 50px;
      }
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: solid 1px $form-border-color;
      border-radius: $form-border-radius;
      position: relative;
      overflow: hidden;
      &.add {
        cursor: pointer;
        svg {
          color: $form-border-color;
        }
        &:hover {
          border-color: $primary-color;
          background-color: rgba(68, 88, 184, 0.04);
          svg {
            color: $primary-color;
          }
        }
      }

      .img__box {
        display: flex;
        width: 100%;
        max-width: 260px;
        overflow: hidden;
        padding: $content-padding;
        position: relative;
        box-sizing: border-box;
        img {
          cursor: pointer;
          width: 100%;
        }
      }

      .file__box {
        display: flex;
        width: 100%;
        padding: $content-padding;
        box-sizing: border-box;
        position: relative;
        span {
          word-break: break-all;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .close__box {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        right: 0px;
        top: 0px;
        z-index: 10;
        svg {
          cursor: pointer;
        }
      }
    }
  }
  .input__box {
    display: none;
  }
}

.form-toast-editor {
  display: flex;
  width: 100%;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: flex-start;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
  .editor__box {
    display: flex;
    flex: 1;
    & > div {
      width: 100%;
    }
  }
}

.form-file-viewer {
  display: flex;
  width: 100%;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
  .file__box {
    display: flex;
    flex: 1;
    height: 56px;
  }
}

.form-file-multiple-viewer {
  display: flex;
  width: 100%;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
  .file__box {
    display: flex;
    flex-direction: column;
    min-height: 56px;
    & > button {
      height: 56px;
      width: fit-content;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.form-address {
  display: flex;
  width: 100%;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
  .address-value__box {
    display: flex;
    width: 100%;
    .zonecode {
      width: 160px;
      margin-right: 10px;
    }
    .address {
      flex: 1;
      margin-right: 10px;
    }
    .detail {
      flex: 1;
    }
    .button__box {
    }
  }
}

.form-text-viewer {
  display: flex;
  width: 100%;
  & > header {
    min-width: 220px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
      color: $header-text-color;
    }
  }
  .viewer__box {
    display: flex;
    align-items: center;
    width: 100%;
    border: solid 1px #bdbdbd;
    border-radius: $form-border-radius;
    padding: 0px 16px;
    height: 56px;
    box-sizing: border-box;
    background: transparent;
    color: rgba(0, 0, 0, 0.38);
  }
  .button__box {
    display: flex;
    margin-left: 20px;
    width: fit-content;
    white-space: nowrap;
  }
}
