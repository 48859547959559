.ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: transparent;
  cursor: pointer;
  &.delete {
    width: 18px;
    height: 18px;
    background: url('./images/ic_delete@3x.png');
    background-size: 100% 100%;
  }
  &.imageholder {
    width: 100px;
    height: 100px;
    background: url('./images/img_imageholder@3x.png');
    background-size: 100% 100%;
  }
  &.logo-small {
    background-image: url('./images/logo@3x.png');
    width: 62px;
    height: 24px;
    background-position: center;
    background-size: cover;
  }
  &.cert-img {
    background-image: url('./images/cert_bitmap@3x.png');
    width: 90px;
    height: 66px;
    background-position: center;
    background-size: cover;
  }
  &.close {
    background-image: url('./images/close@3x.png');
    width: 36px;
    height: 36px;
    background-position: center;
    background-size: cover;
  }
}
