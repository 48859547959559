.basic-page {
  display: flex;
  flex-direction: column;
  width: 100%;

  .example__container {
    display: flex;
    flex-direction: column;
    margin-bottom: $page-padding;

    & > header {
      display: flex;
      margin-bottom: $content-padding;
    }
    .button-list {
      button {
        margin-right: $content-padding;
      }
    }
  }
  .content-header__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: $page-padding;
    align-items: center;
    background: #fff;
    padding: $content-padding;
    box-sizing: border-box;
    border-radius: $basic-border-radius;
    box-shadow: $box-shadow;

    .button__box {
      button {
        margin-right: $content-padding;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .form__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    padding: $content-padding;
    box-sizing: border-box;
    border-radius: $basic-border-radius;
    box-shadow: $box-shadow;

    &.small {
      max-width: 760px;
    }
    & > header {
      display: flex;
      margin-bottom: $content-padding;
    }
    .flex {
      display: flex;
      flex-direction: column;
      & > article {
        margin-bottom: $content-padding;
      }
    }

    .order-list__box {
      display: flex;
      flex-direction: column;

      li {
        padding: 20px;
        border: solid 1px #cecdcd;
        border-radius: 8px;
        margin-bottom: 20px;
        & > header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
        }
        & > article {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .filter__container {
    display: flex;
    flex-direction: column;
    margin-bottom: $page-padding;
  }
  .table__container {
    display: flex;
    flex-direction: column;
    margin-bottom: $page-padding;
  }

  .table-header__container {
    display: flex;
    flex-direction: column;
    padding: $content-padding;
    box-sizing: border-box;
    border-radius: $basic-border-radius;
    box-shadow: $box-shadow;
    background: #fff;

    & > header {
      display: flex;
      margin-bottom: $content-padding;
      justify-content: space-between;
    }
  }
  .paging__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $page-padding;
  }

  .loading__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 240px;
  }
  .card__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    padding: $content-padding;
    box-sizing: border-box;
    border-radius: $basic-border-radius;
    box-shadow: $box-shadow;

    & > header {
      display: flex;
      margin-bottom: $content-padding;
      justify-content: space-between;
    }
    &.no-bg {
      box-shadow: none;
      background: transparent;
      border: solid 1px black;
    }
  }

  .status-info__container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    padding: $content-padding;
    box-sizing: border-box;
    border-radius: $basic-border-radius;
    box-shadow: $box-shadow;
  }
}
