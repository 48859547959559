.modal-action {
  display       : flex;
  flex-direction: column;
  max-height    : 860px;
  overflow-y    : auto;

  &>header {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 24px;
    background     : $table-header-bg-color;
    align-items    : center;
    box-sizing     : border-box;
    padding        : 12px 24px;
  }

  .section__container {
    box-sizing    : border-box;
    display       : flex;
    flex-direction: column;
    margin-bottom : $content-padding;
    border        : solid 1px #c4c4c4;
    border-radius : 8px;
    margin        : 0px 24px 24px 24px;

    &>header {
      margin-bottom   : 12px;
      background-color: #c4c4c430;
      border-bottom   : solid 1px #c4c4c4;
      height          : 48px;
      display         : flex;
      align-items     : center;
      padding         : 0px 24px;

      span {
        font-size  : 18px;
        font-weight: bold;
        color      : #555b67;
      }
    }

    .form-list {
      display       : flex;
      flex-direction: column;
      padding       : 0px 24px;
      padding-bottom: 12px;

      &>article {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .button__container {
    display        : flex;
    justify-content: center;
    margin-bottom  : 24px;
  }
}

.company-list__modal {
  display       : flex;
  flex-direction: column;
  max-height    : 860px;
  overflow-y    : auto;

  &>header {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 24px;
    background     : $table-header-bg-color;
    align-items    : center;
    box-sizing     : border-box;
    padding        : 12px 24px;
  }

  .search__box {
    padding   : 12px 24px;
    box-sizing: border-box;
    display   : flex;
  }

  .list__box {
    padding       : 12px 24px;
    box-sizing    : border-box;
    display       : flex;
    flex-direction: column;

    &>header {
      margin-bottom: 12px;

      em {
        color: $primary-color;
      }
    }

    &>ul {
      display       : flex;
      flex-direction: column;

      li {
        &.header {
          background: $table-header-bg-color;

          &:hover {
            background: $table-header-bg-color;
          }
        }

        cursor     : pointer;
        height     : 48px;
        display    : flex;
        align-items: center;

        .name {
          min-width      : 200px;
          display        : flex;
          justify-content: center;
        }

        .address {
          display        : flex;
          justify-content: center;
          flex           : 1;
        }

        &:hover {
          background: $table-hover-bg-color;
        }

        border-bottom: solid 1px #efefef;
      }
    }
  }

  .paging__box {
    padding        : 12px 24px;
    box-sizing     : border-box;
    display        : flex;
    justify-content: center;
    margin-bottom  : 24px;
  }
}

.order-add__modal {
  display       : flex;
  flex-direction: column;
  max-height    : 860px;
  overflow-y    : auto;

  &>header {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 24px;
    background     : $table-header-bg-color;
    align-items    : center;
    box-sizing     : border-box;
    padding        : 12px 24px;
  }

  .section__container {
    box-sizing    : border-box;
    display       : flex;
    flex-direction: column;
    margin-bottom : $content-padding;
    border        : solid 1px #c4c4c4;
    border-radius : 8px;
    margin        : 0px 24px 24px 24px;

    &>header {
      margin-bottom   : 12px;
      background-color: #c4c4c430;
      border-bottom   : solid 1px #c4c4c4;
      height          : 48px;
      display         : flex;
      align-items     : center;
      padding         : 0px 24px;

      span {
        font-size  : 18px;
        font-weight: bold;
        color      : #555b67;
      }
    }

    .form-list {
      display       : flex;
      flex-direction: column;
      padding       : 0px 24px;
      padding-bottom: 12px;

      &>article {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .button__container {
    display        : flex;
    justify-content: center;
    margin-bottom  : 24px;
  }
}

.certification-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
    height: 64px;
    width: 100%;
    padding: 0px 16px;
    box-sizing: border-box;
  }

  .number__box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;

    span {
      font-size: 14px;
      color: #000000;
    }
  }

  .img__box {
    margin-top: 8px;
    display: flex;
    margin-bottom: 28px;
  }

  .title__box {
    margin-bottom: 8px;

    h1 {
      font-weight: normal;
    }
  }

  .amount__box {
    margin-bottom: 12px;

    h2 {
      color: #5d3be5;
      font-weight: normal;
    }
  }

  .name__box {
    margin-bottom: 28px;

    h2 {
      font-weight: normal;
    }
  }

  .info__box {
    display: flex;
    border-top: solid 1px #161616;
    width: 100%;

    & > ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        height: 48px;
        border-bottom: solid 1px #eeeeee;
        box-sizing: border-box;
        width: 100%;

        &.project {
          min-height: 74px;
        }

        header {
          display: flex;
          min-width: 100px;
          padding-right: 16px;
          padding-left: 20px;

          span {
            font-weight: bold;
          }
        }

        .data {
          display: flex;
          flex-direction: column;

          span {
            line-height: 1.5;

            &.id {
              font-size: 14px;
              color: #777777;
            }
          }
        }
      }
    }
  }

  .desc__box {
    display: flex;
    margin-top: 36px;
    justify-content: center;
    align-items: center;
    text-align: center;

    span {
      font-size: 14px;
      line-height: 1.43;
    }
  }

  .organizer__box {
    margin-top: 32px;
    display: flex;
    justify-content: center;

    span {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .url__box {
    margin-top: 36px;
    margin-bottom: 36px;

    span {
      font-size: 14px;
      color: #777777;
    }
  }
}