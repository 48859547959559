$primary-color: #4458b8;
$secondary-color: #fc312f;
$contrast-color: #fff;

//box-shadow
$box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 4px;
$box-shadow-hover: rgba(56, 72, 157, 0.5) 0px 3px 8px;

//layout
$base-bg-color: #f6f6f9;
$base-border-radius: 6px;

//navigation
$nav-active-bg-color: rgba(255, 255, 255, 0.12);
$nav-bg-color: rgb(15, 23, 42);
$nav-text-color: #a6b0cf;
$nav-active-text-color: #fff;
$title-color: #cccccc;

//text
$defualt-text-color: #555b67;
$placeholder-text-color: rgba(102, 102, 102, 0.87);
$header-text-color: #777777;

//table
$table-header-bg-color: rgba(234, 238, 243, 1);
$table-data-bg-color: #fff;
$table-border-color: rgba(224, 221, 224, 1);
$table-hover-bg-color: #efefef;

//form
$form-border-color: #c4c4c4;
$form-border-radius: 4px;
